import { useEffect } from 'react'
import { Heading, Box, useColorModeValue, Flex, Image } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useRadio from 'hooks/useRadio'
import usePlayer from 'hooks/usePlayer'
import MainLayout from 'layouts/MainLayout'
import Loading from 'components/Loading'
import { isItemColumns, reduceChildItems } from 'utils/genres'
import playerTypes from 'constants/playerTypes'
import { gridSwiperProps, swiperProps } from 'constants/carousel'

const RadioPage = () => {
  const { radio, getRadio, resetRadio } = useRadio()
  const { openPlayer, player } = usePlayer()
  const boxBorderColor = useColorModeValue('gray.300', 'gray.600')
  const boxBg = useColorModeValue('white', 'gray.800')

  useEffect(() => {
    getRadio()

    return () => {
      resetRadio()
    }

    // eslint-disable-next-line
  }, [])

  if (radio.isLoading && !player.isVisible) {
    return <Loading />
  }

  return (
    <MainLayout>
      {radio.genres.map(({ title, items }) => (
        <Box key={title} py='2'>
          <Heading size='md' mb={3}>
            {title}
          </Heading>
          {isItemColumns(title) ? (
            <Swiper {...gridSwiperProps}>
              {reduceChildItems(items).map(item => (
                <SwiperSlide
                  onClick={() => openPlayer(item, title, playerTypes.radio)}
                  key={item.id}
                >
                  <Flex
                    flex={1}
                    borderColor={boxBorderColor}
                    borderWidth={1}
                    rounded={10}
                    p={3}
                    gap={5}
                    alignItems='center'
                    background={boxBg}
                  >
                    <Image src={item.picture} rounded={10} height='60px' />

                    <Heading size='sm' noOfLines={1}>
                      {item.name}
                    </Heading>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper {...swiperProps}>
              {items.map(item => (
                <SwiperSlide
                  onClick={() => openPlayer(item, title, playerTypes.radio)}
                  key={item.id}
                >
                  <Flex
                    borderColor={boxBorderColor}
                    borderWidth={1}
                    rounded={10}
                    flex={1}
                    p={5}
                    justifyContent='center'
                    flexWrap='wrap'
                    bg={boxBg}
                    pointerEvents='none'
                  >
                    <Box flexBasis='100%'>
                      <Image src={item.picture} rounded={10} width='100%' />
                    </Box>
                    <Heading noOfLines={1} textAlign='center' mt='2' size='sm'>
                      {item.name}
                    </Heading>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>
      ))}
    </MainLayout>
  )
}

export default RadioPage
