import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

import userState from 'state/user'
import { initialUserState } from 'state/user/utils'

import useRequest from './useRequest'

const useLogin = () => {
  const [user, setUser] = useRecoilState(userState)
  const { request } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setUser(user => ({ ...user, isLoading })),
    [setUser]
  )

  const getPassword = useCallback(
    async login => {
      setIsLoading(true)
      const data = await request('get', {
        action: 'get_password',
        login
      })
      if (!data.error) {
        setUser(user => ({ ...user, login, isLoading: false }))
      } else {
        setIsLoading(false)
      }
    },
    [request, setUser, setIsLoading]
  )

  const checkPassword = useCallback(
    async password => {
      setIsLoading(true)
      const data = await request('get', {
        action: 'get_info',
        password
      })
      if (!data.error) {
        setUser(user => ({
          ...user,
          password,
          isLoading: false
        }))
      } else {
        setIsLoading(false)
      }
    },
    [request, setUser, setIsLoading]
  )

  const logout = useCallback(() => {
    setUser(initialUserState)
  }, [setUser])

  return {
    user,
    getPassword,
    checkPassword,
    logout
  }
}

export default useLogin
