import { lastListenedTitle, popularTitle } from 'constants/text'

export const reduceItems = genres =>
  genres.reduce((state, item) => {
    return [...state, ...(item.items ?? [])]
  }, [])

export const reduceChildItems = items =>
  items.reduce((state, array) => {
    return [...state, ...array]
  })

export const isItemColumns = title =>
  [popularTitle, lastListenedTitle].includes(title)

export const getGenresSectionItems = (genres, title) => {
  const genre = genres.find(item => item.title === title)
  return genre
    ? isItemColumns(genre.title)
      ? reduceChildItems(genre.items)
      : genre.items
    : []
}

export const reduceMixedItems = list => {
  return list.reduce((state, item) => {
    const { items } = item
    if (items && Array.isArray(items[0])) {
      return [
        ...state,
        {
          ...item,
          isGrid: true,
          items: items.reduce((state, item) => {
            return [...state, ...item]
          }, [])
        }
      ]
    }
    return [...state, item]
  }, [])
}
