import { favoriteTitle, lastListenedTitle, popularTitle } from 'constants/text'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import bookState from 'state/book'

import useRequest from './useRequest'
import { reduceItems } from 'utils/genres'

const useBooks = () => {
  const [books, setBooks] = useRecoilState(bookState)
  const { request, nativeRequest } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setBooks(books => ({ ...books, isLoading })),
    [setBooks]
  )

  const setMoreIsLoading = useCallback(
    isLoading =>
      setBooks(books => ({
        ...books,
        more: { ...books.more, isLoading }
      })),
    [setBooks]
  )

  const getFavoriteBooks = useCallback(async () => {
    const data = await request('get', {
      action: 'get_favorite',
      section: 'books'
    })
    if (!data.error) {
      const genres = data.books_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getPopularBooks = useCallback(async () => {
    const data = await request('get', {
      action: 'get_trending',
      section: 'book'
    })

    if (!data.error) {
      const genres = data.book_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getLastListenedBooks = useCallback(async () => {
    const data = await request('get', {
      action: 'get_lastlisten',
      section: 'book'
    })
    if (!data.error) {
      const genres = data.book_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getBooks = useCallback(async () => {
    setIsLoading(true)
    const data = await request('get', {
      action: 'get',
      section: 'book'
    })
    if (!data.error) {
      const favoriteItems = await getFavoriteBooks()
      const lastListenedItems = await getLastListenedBooks()
      const popularItems = await getPopularBooks()

      const genres =
        [
          { title: favoriteTitle, items: favoriteItems },
          { title: lastListenedTitle, items: lastListenedItems },
          { title: popularTitle, items: popularItems },
          ...data.book_genres
        ] ?? []

      setBooks(books => ({
        ...books,
        genres: genres.filter(genre => genre.items.length),
        isLoading: false
      }))
    } else {
      setIsLoading(false)
    }
  }, [
    setBooks,
    setIsLoading,
    request,
    getFavoriteBooks,
    getPopularBooks,
    getLastListenedBooks
  ])

  const getMoreBooks = useCallback(
    async (title, url) => {
      setMoreIsLoading(true)
      const data = await nativeRequest(url)
      if (!data.error) {
        setBooks(books => ({
          ...books,
          more: {
            title,
            items: [
              ...(
                books.genres.find(item => item.title === title) || {
                  items: []
                }
              ).items,
              ...(data.items ?? [])
            ],
            isLoading: false
          }
        }))
      } else {
        setMoreIsLoading(false)
      }
    },
    [setBooks, setMoreIsLoading, nativeRequest]
  )

  const resetBooks = useCallback(() => {
    setBooks(books => ({
      ...books,
      genres: [],
      more: { ...books.more, items: [], title: null }
    }))
  }, [setBooks])

  return {
    books,
    getBooks,
    resetBooks,
    getMoreBooks
  }
}

export default useBooks
