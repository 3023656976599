import { HashRouter } from 'react-router-dom'
import { createStandaloneToast } from '@chakra-ui/react'

import AppRouter from 'routes/Router'
import ToastContext from 'contexts/ToastContext'
import UserContext from 'contexts/UserContext'
import useLogin from 'hooks/useLogin'

const { ToastContainer, toast } = createStandaloneToast()

const App = () => {
  const { user } = useLogin()

  return (
    <UserContext.Provider value={user}>
      <HashRouter>
        <ToastContext.Provider value={toast}>
          <ToastContainer />
          <AppRouter />
        </ToastContext.Provider>
      </HashRouter>
    </UserContext.Provider>
  )
}

export default App
