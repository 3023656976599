import userLocalStorage from 'utils/userLocalStorage'

export const localStorageSetEffect = ({ onSet }) => {
  onSet(({ login, password }) => {
    userLocalStorage.set({ login, password })
  })
}

export const initialUserState = {
  login: '',
  password: '',
  isLoading: false
}

export const getDefaultState = () => {
  const user = userLocalStorage.get()
  if (
    !(user.login && user.login.length && user.password && user.password.length)
  ) {
    userLocalStorage.destroy()
    return initialUserState
  }

  return {
    ...initialUserState,
    ...user
  }
}
