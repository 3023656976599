import { favoriteTitle, lastListenedTitle, popularTitle } from 'constants/text'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

import podcastState from 'state/podcast'
import { reduceItems } from 'utils/genres'
import useRequest from './useRequest'

const usePodcasts = () => {
  const [podcasts, setPodcasts] = useRecoilState(podcastState)
  const { request, nativeRequest } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setPodcasts(podcasts => ({ ...podcasts, isLoading })),
    [setPodcasts]
  )

  const setMoreIsLoading = useCallback(
    isLoading =>
      setPodcasts(podcasts => ({
        ...podcasts,
        more: { ...podcasts.more, isLoading }
      })),
    [setPodcasts]
  )

  const getFavoritePodcasts = useCallback(async () => {
    const data = await request('get', {
      action: 'get_favorite',
      section: 'podcast'
    })
    if (!data.error) {
      const genres = data.podcast_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getPopularPodcasts = useCallback(async () => {
    const data = await request('get', {
      action: 'get_trending',
      section: 'podcast'
    })

    if (!data.error) {
      const genres = data.podcast_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getLastListenedPodcasts = useCallback(async () => {
    const data = await request('get', {
      action: 'get_lastlisten',
      section: 'podcast'
    })
    if (!data.error) {
      const genres = data.podcast_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getPodcasts = useCallback(async () => {
    setIsLoading(true)
    const data = await request('get', {
      action: 'get',
      section: 'podcast'
    })
    if (!data.error) {
      const favoriteItems = await getFavoritePodcasts()
      const lastListenedItems = await getLastListenedPodcasts()
      const popularItems = await getPopularPodcasts()

      const genres =
        [
          { title: favoriteTitle, items: favoriteItems },
          { title: lastListenedTitle, items: lastListenedItems },
          { title: popularTitle, items: popularItems },
          ...data.podcast_genres
        ] ?? []

      setPodcasts(podcasts => ({
        ...podcasts,
        genres: genres.filter(genre => genre.items.length),
        isLoading: false
      }))
    } else {
      setIsLoading(false)
    }
  }, [
    setPodcasts,
    setIsLoading,
    request,
    getFavoritePodcasts,
    getPopularPodcasts,
    getLastListenedPodcasts
  ])

  const getMorePodcasts = useCallback(
    async (title, url) => {
      setMoreIsLoading(true)
      const data = await nativeRequest(url)
      if (!data.error) {
        setPodcasts(podcasts => ({
          ...podcasts,
          more: {
            title,
            items: [
              ...(
                podcasts.genres.find(item => item.title === title) || {
                  items: []
                }
              ).items,
              ...(data.items ?? [])
            ],
            isLoading: false
          }
        }))
      } else {
        setMoreIsLoading(false)
      }
    },
    [setPodcasts, setMoreIsLoading, nativeRequest]
  )

  const resetPodcasts = useCallback(() => {
    setPodcasts(podcasts => ({
      ...podcasts,
      genres: [],
      more: { ...podcasts.more, items: [], title: null }
    }))
  }, [setPodcasts])

  return {
    podcasts,
    getPodcasts,
    resetPodcasts,
    getMorePodcasts
  }
}

export default usePodcasts
