import {
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  Image,
  Text,
  Spinner
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import useSearch from 'hooks/useSearch'
import usePlayer from 'hooks/usePlayer'

const Search = ({ onClose }) => {
  const { onSearch, search, clearSearch } = useSearch()
  const { openPlayer } = usePlayer()

  const handleClose = () => {
    clearSearch()
    onClose()
  }

  const boxBorderColor = useColorModeValue('gray.300', 'gray.600')
  const sectionItemBg = useColorModeValue('white', 'gray.700')

  return (
    <Flex flexDir='column' align='center' justifyContent='center'>
      <InputGroup>
        <InputLeftElement pointerEvents='none' children={<FiSearch />} />
        <Input
          onChange={onSearch}
          value={search.text}
          autoFocus
          type='text'
          placeholder='Введите текст для поиска'
        />
      </InputGroup>

      {!search.result.length &&
        !!search.text.length &&
        (search.isLoading ? (
          <Spinner mt={4} />
        ) : search.text.length > 3 ? (
          <Text mt={4} fontSize='18px'>
            По вашему запросу ничего не найдено
          </Text>
        ) : null)}

      <Flex
        mt='4'
        flexDir='column'
        width='100%'
        paddingX={{ base: 0, lg: '6' }}
        gap='2'
      >
        {search.result.map(({ title, items }) => (
          <Flex flexDir='column' mb='4' key={title}>
            <Heading size='md' textAlign='center' mb='3'>
              {title}
            </Heading>
            {items.map(item => (
              <Flex
                key={item.id}
                borderColor={boxBorderColor}
                borderWidth={1}
                rounded={10}
                p={3}
                gap={5}
                alignItems='center'
                background={sectionItemBg}
                mb={2}
                onClick={() => {
                  openPlayer(item, title, item.objectType)
                  handleClose()
                }}
              >
                <Image src={item.picture} rounded={6} height='60px' />
                <Heading size='sm' noOfLines={3}>
                  {item.name}
                </Heading>
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default Search
