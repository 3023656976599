import { atom } from 'recoil'

const objectState = atom({
  key: 'state/object',
  default: {
    chapters: [],
    info: null,
    title: null,
    isLoading: false
  }
})

export default objectState
