import React from 'react'
import { createRoot } from 'react-dom/client'

import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import { RecoilRoot } from 'recoil'

import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import './index.css'

import App from './App'

const container = document.getElementById('root')

const root = createRoot(container)

const { location } = window

if (location.protocol !== 'https:') {
  location.replace(`https:${location.href.substring(location.protocol.length)}`)
}

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ChakraProvider>
        <CSSReset />
        <App />
      </ChakraProvider>
    </RecoilRoot>
  </React.StrictMode>
)
