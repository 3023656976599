import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { FiRadio, FiMic, FiMusic, FiBook, FiHome } from 'react-icons/fi'

import * as path from 'routes/path'
import MobileNav from 'components/MobileNav'
import SidebarContent from 'components/SidebarContent'
import Player from 'components/Player'
import usePlayer from 'hooks/usePlayer'
import Search from 'components/Search'

const MainLayout = ({ children }) => {
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose
  } = useDisclosure()
  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose
  } = useDisclosure()
  const { player } = usePlayer()

  const links = [
    { path: path.HOME, title: 'Главная', icon: FiHome },
    { path: path.RADIO, title: 'Радио', icon: FiRadio },
    { path: path.PODCASTS, title: 'Подкасты', icon: FiMic },
    { path: path.MUSIC, title: 'Музыка', icon: FiMusic },
    { path: path.BOOKS, title: 'Аудиокниги', icon: FiBook }
  ]

  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        links={links}
        onClose={() => onMenuClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isMenuOpen}
        placement='left'
        onClose={onMenuClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent links={links} onClose={onMenuClose} />
        </DrawerContent>
      </Drawer>
      <Drawer
        autoFocus={false}
        isOpen={isSearchOpen}
        placement='right'
        onClose={onSearchClose}
        size='md'
      >
        <DrawerOverlay />
        <DrawerContent bg={useColorModeValue('white', 'gray.800')}>
          <DrawerCloseButton zIndex={10001} />
          <DrawerHeader>Поиск</DrawerHeader>
          <DrawerBody>
            <Search onClose={onSearchClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <MobileNav onMenuOpen={onMenuOpen} onSearchOpen={onSearchOpen} />
      <Container maxW='8xl'>
        <Box
          ml={{ base: 0, md: 60 }}
          paddingY='4'
          paddingX='2'
          mb={player.isVisible ? '80px' : undefined}
        >
          {children}
        </Box>
      </Container>
      <Player />
    </Box>
  )
}

export default MainLayout
