import { favoriteTitle, lastListenedTitle, popularTitle } from 'constants/text'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import musicState from 'state/music'

import useRequest from './useRequest'
import { reduceItems } from 'utils/genres'

const useMusic = () => {
  const [music, setMusic] = useRecoilState(musicState)
  const { request, nativeRequest } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setMusic(music => ({ ...music, isLoading })),
    [setMusic]
  )

  const setMoreIsLoading = useCallback(
    isLoading =>
      setMusic(music => ({
        ...music,
        more: { ...music.more, isLoading }
      })),
    [setMusic]
  )

  const getFavoriteMusic = useCallback(async () => {
    const data = await request('get', {
      action: 'get_favorite',
      section: 'music'
    })
    if (!data.error) {
      const genres = data.music_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getPopularMusic = useCallback(async () => {
    const data = await request('get', {
      action: 'get_trending',
      section: 'music'
    })

    if (!data.error) {
      const genres = data.music_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getLastListenedMusic = useCallback(async () => {
    const data = await request('get', {
      action: 'get_lastlisten',
      section: 'music'
    })
    if (!data.error) {
      const genres = data.music_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getMusic = useCallback(async () => {
    setIsLoading(true)
    const data = await request('get', {
      action: 'get',
      section: 'music'
    })
    if (!data.error) {
      const favoriteItems = await getFavoriteMusic()
      const lastListenedItems = await getLastListenedMusic()
      const popularItems = await getPopularMusic()

      const genres =
        [
          { title: favoriteTitle, items: favoriteItems },
          { title: lastListenedTitle, items: lastListenedItems },
          { title: popularTitle, items: popularItems },
          ...data.music_genres
        ] ?? []

      setMusic(music => ({
        ...music,
        genres: genres.filter(genre => genre.items.length),
        isLoading: false
      }))
    } else {
      setIsLoading(false)
    }
  }, [
    setMusic,
    setIsLoading,
    request,
    getFavoriteMusic,
    getPopularMusic,
    getLastListenedMusic
  ])

  const getMoreMusic = useCallback(
    async (title, url) => {
      setMoreIsLoading(true)
      const data = await nativeRequest(url)
      if (!data.error) {
        setMusic(music => ({
          ...music,
          more: {
            title,
            items: [
              ...(
                music.genres.find(item => item.title === title) || {
                  items: []
                }
              ).items,
              ...(data.items ?? [])
            ],
            isLoading: false
          }
        }))
      } else {
        setMoreIsLoading(false)
      }
    },
    [setMusic, setMoreIsLoading, nativeRequest]
  )

  const resetMusic = useCallback(() => {
    setMusic(music => ({
      ...music,
      genres: [],
      more: { ...music.more, items: [], title: null }
    }))
  }, [setMusic])

  return {
    music,
    getMusic,
    resetMusic,
    getMoreMusic
  }
}

export default useMusic
