import { atom } from 'recoil'

const podcastState = atom({
  key: 'state/podcast',
  default: {
    genres: [],
    isLoading: false,
    more: {
      title: null,
      items: [],
      isLoading: false
    }
  }
})

export default podcastState
