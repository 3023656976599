import UserContext from 'contexts/UserContext'
import { useCallback, useContext } from 'react'

import { request, nativeRequest } from 'utils/request'

import useToast from './useToast'

const useRequest = () => {
  const toast = useToast()
  const user = useContext(UserContext)

  const internalRequest = useCallback(
    async (method, params, body = null) => {
      try {
        return await request(user, method, params, body)
      } catch (e) {
        toast({
          title: 'Ошибка',
          description: e.message,
          status: 'error'
        })
        return { error: true }
      }
    },
    [toast, user]
  )

  const internalNativeRequest = useCallback(
    async url => {
      try {
        return await nativeRequest(url)
      } catch (e) {
        toast({
          title: 'Ошибка',
          description: e.message,
          status: 'error'
        })
        return { error: true }
      }
    },
    [toast]
  )

  return {
    request: internalRequest,
    nativeRequest: internalNativeRequest
  }
}

export default useRequest
