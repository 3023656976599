import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import Hls from 'hls.js'
import playerState from 'state/player'
import getPlayerNode from 'utils/getPlayerNode'
import playerTypes from 'constants/playerTypes'
import useToast from './useToast'
import useRequest from './useRequest'
import useRadio from './useRadio'
import usePodcasts from './usePodcasts'
import useMusic from './useMusic'
import useBooks from './useBooks'

const hls =
  Hls.isSupported() && new Hls({ debug: false, maxMaxBufferLength: 15 })
const playerNode = getPlayerNode()
let interval = null

const usePlayer = () => {
  const toast = useToast()
  const { request } = useRequest()
  const { getRadio } = useRadio()
  const { getPodcasts } = usePodcasts()
  const { getMusic } = useMusic()
  const { getBooks } = useBooks()
  const [player, setPlayer] = useRecoilState(playerState)

  useEffect(() => {
    try {
      if (player.isMounted) {
        if (!player.isPaused) {
          if (player.source.listened_duration) {
            playerNode.currentTime = player.source?.listened_duration
          }
          playerNode.play()
          if (
            player.type !== playerTypes.radio ??
            player.source?.objType !== playerTypes.radio
          ) {
            clearInterval(interval)
            interval = setInterval(() => {
              request(
                'post',
                {
                  action: 'send_listening_item',
                  current_time: playerNode.currentTime ?? 0,
                  id: player.source.id,
                  chapter_id: player.source.chapter_id
                },
                {
                  postData: {
                    current_time: playerNode.currentTime ?? 0,
                    playedItem: player.source
                  }
                }
              )
            }, 30 * 1000)
          }
        } else {
          clearInterval(interval)
          playerNode.pause()
        }

        if (!player.isVisible) {
          hls.stopLoad()
        }

        if (player.isMuted) {
          playerNode.muted = true
        } else {
          playerNode.muted = false
        }
      }
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: 'Ошибка при воспроизведении, попробуйте ещё раз',
        status: 'error'
      })
    }
  }, [player, toast, request])

  const closePlayer = useCallback(() => {
    setPlayer(player => ({ ...player, isVisible: false, isPaused: true }))
  }, [setPlayer])

  const openPlayer = useCallback(
    (source, parentTitle, type) => {
      try {
        if (
          type === playerTypes.radio ||
          source?.objType === playerTypes.radio
        ) {
          if (hls) {
            hls.stopLoad()
            hls.attachMedia(playerNode)

            hls.on(Hls.Events.MEDIA_ATTACHED, () => {
              hls.loadSource(source.url)
              hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setPlayer(player => ({
                  ...player,
                  source,
                  parentTitle,
                  type,
                  isVisible: true,
                  isPaused: false,
                  isMuted: false,
                  isMounted: true
                }))
              })
              hls.on(Hls.Events.ERROR, (_, data) => {
                console.warn(data)
              })
            })
          } else if (playerNode.canPlayType('application/vnd.apple.mpegurl')) {
            playerNode.src = source.url
            playerNode.addEventListener('canplay', () => {
              setPlayer(player => ({
                ...player,
                source,
                parentTitle,
                type,
                isVisible: true,
                isPaused: false,
                isMuted: false,
                isMounted: true
              }))
            })
          }
        } else {
          playerNode.src = source.url
          setPlayer(player => ({
            ...player,
            parentTitle,
            source,
            type,
            isVisible: true,
            isMounted: true,
            isPaused: false,
            isMuted: false
          }))
        }
      } catch (error) {
        toast({
          title: 'Ошибка',
          description: error.message,
          status: 'error'
        })
      }
    },
    [setPlayer, toast]
  )

  const togglePlayerVolume = useCallback(() => {
    setPlayer(player => ({
      ...player,
      isMuted: !player.isMuted
    }))
  }, [setPlayer])

  const togglePlayerPause = useCallback(() => {
    setPlayer(player => ({
      ...player,
      isPaused: !player.isPaused
    }))
  }, [setPlayer])

  const backward = useCallback(() => {
    if (
      player.type !== playerTypes.radio ??
      player.source?.objType !== playerTypes.radio
    ) {
      playerNode.currentTime -= 10
    }
  }, [player])

  const forward = useCallback(() => {
    if (
      player.type !== playerTypes.radio ??
      player.source?.objType !== playerTypes.radio
    ) {
      playerNode.currentTime += 10
    }
  }, [player])

  const setIsFavoriteLoading = useCallback(
    isFavoriteLoading => {
      setPlayer(player => ({
        ...player,
        isFavoriteLoading
      }))
    },
    [setPlayer]
  )

  const toggleIsFavorite = useCallback(
    async (source, type) => {
      setIsFavoriteLoading(true)
      const data = await request('get', {
        id: source.id,
        action: source.favorite ? 'favorite_delete' : 'favorite_add'
      })

      if (!data.error) {
        setPlayer(player => ({
          ...player,
          source: { ...source, favorite: !source.favorite },
          isFavoriteLoading: false
        }))

        switch (type) {
          case playerTypes.radio:
            getRadio()
            break
          case playerTypes.podcasts:
            getPodcasts()
            break
          case playerTypes.music:
            getMusic()
            break
          case playerTypes.books:
            getBooks()
            break
          default:
        }
      } else {
        setIsFavoriteLoading(false)
      }
    },
    [
      request,
      setIsFavoriteLoading,
      setPlayer,
      getBooks,
      getMusic,
      getPodcasts,
      getRadio
    ]
  )

  return {
    closePlayer,
    openPlayer,
    togglePlayerVolume,
    togglePlayerPause,
    backward,
    forward,
    toggleIsFavorite,
    player
  }
}

export default usePlayer
