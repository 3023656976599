import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import searchState from 'state/search'
import useRequest from './useRequest'

const useSearch = () => {
  const [search, setSearch] = useRecoilState(searchState)
  const { request } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setSearch(search => ({ ...search, isLoading })),
    [setSearch]
  )

  const getSearchResult = useCallback(
    async search => {
      setIsLoading(true)
      const data = await request('get', { action: 'search', search })
      if (!data.error) {
        setSearch(search => ({
          ...search,
          result: data.objects,
          isLoading: false
        }))
      } else {
        setIsLoading(false)
      }
    },
    [setIsLoading, request, setSearch]
  )

  useEffect(() => {
    const seachText = search.text.trim()
    if (seachText.length) {
      getSearchResult(seachText)
    }
    return () => {
      setSearch(search => ({
        ...search,
        result: []
      }))
    }
  }, [search.text, setSearch, getSearchResult])

  const onSearch = useCallback(
    event => {
      event.persist()
      const { value } = event.target
      setSearch(search => ({
        ...search,
        text: value
      }))
    },
    [setSearch]
  )

  const clearSearch = useCallback(() => {
    setSearch(search => ({
      ...search,
      result: [],
      text: ''
    }))
  }, [setSearch])

  return {
    search,
    onSearch,
    clearSearch
  }
}

export default useSearch
