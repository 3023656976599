import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import homeState from 'state/home'
import { reduceMixedItems } from 'utils/genres'

import useRequest from './useRequest'

const useHome = () => {
  const [home, setHome] = useRecoilState(homeState)

  const { request, nativeRequest } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setHome(home => ({ ...home, isLoading })),
    [setHome]
  )

  const setMoreIsLoading = useCallback(
    isLoading =>
      setHome(home => ({
        ...home,
        more: { ...home.more, isLoading }
      })),
    [setHome]
  )

  const getHome = useCallback(async () => {
    setIsLoading(true)
    const data = await request('get', {
      action: 'get_main_page'
    })
    if (!data.error) {
      const genres = reduceMixedItems(data.genres)

      setHome(home => ({
        ...home,
        genres: reduceMixedItems(genres),
        isLoading: false
      }))
    } else {
      setIsLoading(false)
    }
  }, [setHome, setIsLoading, request])

  const getMoreHome = useCallback(
    async (title, url) => {
      setMoreIsLoading(true)
      const data = await nativeRequest(url)
      if (!data.error) {
        setHome(home => ({
          ...home,
          more: {
            title,
            items: [
              ...(
                home.genres.find(item => item.title === title) || {
                  items: []
                }
              ).items,
              ...(data.items ?? [])
            ],
            isLoading: false
          }
        }))
      } else {
        setMoreIsLoading(false)
      }
    },
    [nativeRequest, setHome, setMoreIsLoading]
  )

  const resetHome = useCallback(() => {
    setHome(home => ({
      ...home,
      genres: [],
      more: { ...home.more, items: [], title: null }
    }))
  }, [setHome])

  return {
    home,
    getHome,
    getMoreHome,
    resetHome
  }
}

export default useHome
