import {
  Flex,
  IconButton,
  useColorModeValue,
  Text,
  HStack,
  Menu,
  MenuButton,
  Avatar,
  VStack,
  MenuList,
  MenuItem,
  Box,
  useColorMode
} from '@chakra-ui/react'
import { FiMoon, FiSun, FiChevronDown, FiMenu, FiSearch } from 'react-icons/fi'
import useLogin from 'hooks/useLogin'
import Theme from 'constants/theme'

const MobileNav = ({ onMenuOpen, onSearchOpen, ...props }) => {
  const { user, logout } = useLogin()
  const { colorMode, toggleColorMode } = useColorMode()

  const isDarkTheme = colorMode === Theme.Dark

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...props}
    >
      <Flex gap='2' mr={{ base: '0', md: '2' }}>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onMenuOpen}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
        />
        <IconButton
          onClick={onSearchOpen}
          variant='outline'
          aria-label='search'
          icon={<FiSearch />}
        />
      </Flex>
      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize='2xl'
        fontFamily='monospace'
        fontWeight='bold'
      >
        Sado
      </Text>

      <HStack spacing={{ base: '2', md: '6' }}>
        <IconButton
          size='lg'
          variant='ghost'
          aria-label='toggle theme'
          onClick={toggleColorMode}
          icon={isDarkTheme ? <FiSun /> : <FiMoon />}
        />
        <Flex alignItems='center'>
          <Menu>
            <MenuButton
              py={2}
              transition='all 0.3s'
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size='sm'
                  bg={useColorModeValue('gray.300', 'gray.600')}
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>Логин</Text>
                  <Text
                    fontSize='xs'
                    color={useColorModeValue('gray.600', 'gray.400')}
                  >
                    {user.login}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              zIndex={1100}
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={logout}>Выход</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

export default MobileNav
