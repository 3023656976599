import { atom } from 'recoil'

const playerState = atom({
  key: 'state/player',
  default: {
    source: null,
    parentTitle: null,
    type: null,
    isVisible: false,
    isMuted: false,
    isPaused: false,
    isMounted: false,
    isFavoriteLoading: false
  }
})

export default playerState
