import { useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom'

import RadioPage from 'pages/RadioPage'
import PodcastsPage from 'pages/PodcastsPage'
import LoginPage from 'pages/LoginPage'
import MusicPage from 'pages/MusicPage'
import BooksPage from 'pages/BooksPage'
import HomePage from 'pages/HomePage'

import useLogin from 'hooks/useLogin'

import Loading from 'components/Loading'

import * as path from './path'

const AppRouter = () => {
  const {
    user: { login, password }
  } = useLogin()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!(login.length && password.length)) {
      navigate(path.LOGIN)
    } else {
      if ([path.LOADING, path.LOGIN].includes(pathname)) {
        navigate(path.HOME)
      }
    }
  }, [login, password, navigate, pathname])

  return (
    <Routes>
      <Route path={path.LOADING} element={<Loading />} />
      <Route path={path.RADIO} element={<RadioPage />} />
      <Route path={path.PODCASTS} element={<PodcastsPage />} />
      <Route path={path.MUSIC} element={<MusicPage />} />
      <Route path={path.BOOKS} element={<BooksPage />} />
      <Route path={path.LOGIN} element={<LoginPage />} />
      <Route path={path.HOME} element={<HomePage />} />
      <Route path='*' element={<Navigate to={path.HOME} replace />} />
    </Routes>
  )
}

export default AppRouter
