import { Spinner, Stack } from '@chakra-ui/react'

const Loading = () => {
  return (
    <Stack
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='fixed'
      top='0'
      left='0'
      right='0'
      bottom='0'
    >
      <Spinner
        thickness='4px'
        speed='1.5s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
        width={200}
        height={200}
        borderWidth='10px'
      />
    </Stack>
  )
}

export default Loading
