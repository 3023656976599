import { Flex, useColorModeValue } from '@chakra-ui/react'

const LoginLayout = ({ children }) => {
  const mainFlexBgColor = useColorModeValue('gray.50', 'gray.800')

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={mainFlexBgColor}
    >
      {children}
    </Flex>
  )
}

export default LoginLayout
