import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import objectState from 'state/object'
import useRequest from './useRequest'

const useObject = () => {
  const [object, setObject] = useRecoilState(objectState)
  const { request } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setObject(object => ({ ...object, isLoading })),
    [setObject]
  )

  const getObject = useCallback(
    async id => {
      setIsLoading(true)

      const data = await request('get', {
        action: 'get_object_items',
        id
      })

      if (!data.error) {
        const {
          title,
          items: { chapters, info }
        } = data
        setObject(object => ({
          ...object,
          chapters,
          info,
          title,
          isLoading: false
        }))
      } else {
        setIsLoading(false)
      }
    },
    [setObject, request, setIsLoading]
  )

  const resetObject = useCallback(() => {
    setObject(object => ({
      ...object,
      chapters: [],
      info: null,
      title: null
    }))
  }, [setObject])

  return {
    object,
    getObject,
    resetObject
  }
}

export default useObject
