import { atom } from 'recoil'
import { getDefaultState, localStorageSetEffect } from './utils'

const userState = atom({
  key: 'state/user',
  default: getDefaultState(),
  effects: [localStorageSetEffect]
})

export default userState
