import { useEffect, useRef } from 'react'
import {
  Flex,
  IconButton,
  useColorModeValue,
  Image,
  Heading,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Text,
  GridItem,
  Grid,
  Spinner
} from '@chakra-ui/react'
import {
  FiX,
  FiVolume2,
  FiVolumeX,
  FiPause,
  FiPlay,
  FiFastForward,
  FiRewind,
  FiHeart
} from 'react-icons/fi'
import { FaHeart } from 'react-icons/fa'

import usePlayer from 'hooks/usePlayer'
import useRadio from 'hooks/useRadio'
import usePodcasts from 'hooks/usePodcasts'
import playerTypes from 'constants/playerTypes'
import { getGenresSectionItems } from 'utils/genres'
import useObject from 'hooks/useObject'
import useMusic from 'hooks/useMusic'
import useBooks from 'hooks/useBooks'
import useHome from 'hooks/useHome'

const Player = () => {
  const {
    player,
    closePlayer,
    togglePlayerVolume,
    togglePlayerPause,
    backward,
    forward,
    openPlayer,
    toggleIsFavorite
  } = usePlayer()
  const { object, getObject, resetObject } = useObject()
  const { radio } = useRadio()
  const { podcasts } = usePodcasts()
  const { music } = useMusic()
  const { books } = useBooks()
  const { home } = useHome()

  const boxBorderColor = useColorModeValue('gray.300', 'gray.600')
  const boxBg = useColorModeValue('white', 'gray.800')
  const sectionItemBg = useColorModeValue('white', 'gray.700')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const drawerContentRef = useRef(null)

  const hasItems = player.source?.hasItems
  const picture = player.source?.picture
  const favorite = player.source?.favorite
  const name = player.source?.name
  const sectionTitle = object.title || player.parentTitle

  useEffect(() => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scroll(0, 0)
    }
  }, [player.source])

  useEffect(() => {
    if (isOpen && hasItems) {
      getObject(player.source.id)
    }

    return () => {
      resetObject()
    }
  }, [isOpen, getObject, resetObject, hasItems, player.source])

  const getSectionItems = () => {
    if (object.title && object.chapters.length) {
      return object.chapters
    }
    switch (player.type) {
      case playerTypes.radio:
        return getGenresSectionItems(radio.genres, player.parentTitle)
      case playerTypes.podcasts:
        return getGenresSectionItems(podcasts.genres, player.parentTitle)
      case playerTypes.music:
        return getGenresSectionItems(music.genres, player.parentTitle)
      case playerTypes.books:
        return getGenresSectionItems(books.genres, player.parentTitle)
      case playerTypes.home:
        return getGenresSectionItems(home.genres, player.parentTitle)
      default:
        return []
    }
  }

  return (
    <>
      <Flex
        w='100%'
        display={player.isVisible && !isOpen ? 'flex' : 'none'}
        position='fixed'
        height='80px'
        zIndex={1000}
        bottom={0}
        background={useColorModeValue('white', 'gray.800')}
        border='1px solid'
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Flex
          borderRadius={10}
          w='100%'
          justifyContent='space-between'
          alignItems='center'
          gap='2'
          px='2'
          maxW='600'
          marginX='auto'
        >
          <IconButton
            variant='outline'
            aria-label='favorite'
            display={{ base: 'none', md: 'flex' }}
            icon={favorite ? <FaHeart /> : <FiHeart />}
            onClick={() => toggleIsFavorite(player.source, player.type)}
            isLoading={player.isFavoriteLoading}
            color={favorite ? 'red.600' : undefined}
          />

          <IconButton
            onClick={togglePlayerVolume}
            variant='outline'
            aria-label='mute player'
            icon={player.isMuted ? <FiVolumeX /> : <FiVolume2 />}
          />

          <IconButton
            aria-label='rewind'
            onClick={backward}
            display={{ base: 'none', sm: 'flex' }}
            icon={<FiRewind />}
            variant='outline'
          />

          <Flex alignItems='center' gap={4} w='240px' onClick={onOpen}>
            <Image src={picture} rounded={6} height='40px' width='40px' />

            <Heading size='sm' noOfLines={2}>
              {name}
            </Heading>
          </Flex>

          <IconButton
            display={{ base: 'none', sm: 'flex' }}
            aria-label='fast forward'
            onClick={forward}
            icon={<FiFastForward />}
            variant='outline'
          />

          <IconButton
            onClick={togglePlayerPause}
            variant='outline'
            aria-label='pause player'
            autoFocus
            icon={player.isPaused ? <FiPlay /> : <FiPause />}
          />

          <IconButton
            onClick={closePlayer}
            variant='outline'
            aria-label='close player'
            icon={<FiX />}
          />
        </Flex>
      </Flex>
      <Drawer onClose={onClose} isOpen={isOpen} size='md'>
        <DrawerOverlay />
        <DrawerContent bg={boxBg}>
          <DrawerCloseButton zIndex={10001} />
          <DrawerBody ref={drawerContentRef}>
            <Heading pt='4' size='xl' textAlign='center'>
              Играет сейчас
            </Heading>
            <Flex
              pt='10'
              align='center'
              justifyContent='center'
              flexDir='column'
            >
              <Image src={picture} rounded={6} height='150px' mb='2' />
              <Flex
                position='sticky'
                top={'-0.5rem'}
                bg={boxBg}
                width='108%'
                justifyContent='center'
                alignItems='center'
                zIndex='10000'
                paddingY={4}
                paddingX={5}
              >
                <Heading
                  noOfLines={3}
                  title={name}
                  size='md'
                  textAlign='center'
                >
                  {name}
                </Heading>
              </Flex>

              <Flex mt='6' gap='4'>
                <IconButton
                  aria-label='rewind'
                  onClick={backward}
                  icon={<FiRewind />}
                  variant='outline'
                  rounded='50%'
                  size='lg'
                />

                <IconButton
                  variant='outline'
                  aria-label='favorite'
                  icon={favorite ? <FaHeart /> : <FiHeart />}
                  onClick={() => toggleIsFavorite(player.source, player.type)}
                  isLoading={player.isFavoriteLoading}
                  color={favorite ? 'red.600' : undefined}
                  rounded='50%'
                  size='lg'
                />

                <IconButton
                  onClick={togglePlayerPause}
                  variant='outline'
                  aria-label='pause player'
                  icon={player.isPaused ? <FiPlay /> : <FiPause />}
                  rounded='50%'
                  autoFocus
                  size='lg'
                />

                <IconButton
                  onClick={togglePlayerVolume}
                  variant='outline'
                  aria-label='mute player'
                  icon={player.isMuted ? <FiVolumeX /> : <FiVolume2 />}
                  rounded='50%'
                  size='lg'
                />
                <IconButton
                  aria-label='fast forward'
                  onClick={forward}
                  icon={<FiFastForward />}
                  variant='outline'
                  rounded='50%'
                  size='lg'
                />
              </Flex>

              <Flex
                mt='3'
                gap='4'
                flexWrap='wrap'
                paddingX={{ base: 0, lg: '6' }}
                width='100%'
              >
                {object.isLoading && <Spinner margin='20px auto' />}
                <Text fontSize='sm' width='100%' mb='4'>
                  {object.info?.description}
                </Text>
                <Grid gridTemplateColumns='1fr 1fr' gridGap='2' w='100%'>
                  {(object.info?.data || []).map(item => (
                    <GridItem mb='2' key={item.label}>
                      <Heading size='xs'>{item.label}</Heading>
                      <Text>{item.value}</Text>
                    </GridItem>
                  ))}
                </Grid>
              </Flex>

              <Heading mt='6' size='lg'>
                {sectionTitle}
              </Heading>
              <Flex
                mt='6'
                flexDir='column'
                width='100%'
                paddingX={{ base: 0, lg: '6' }}
              >
                {getSectionItems().map(item => (
                  <Flex
                    key={hasItems ? item.chapter_id : item.id}
                    borderColor={boxBorderColor}
                    borderWidth={1}
                    rounded={10}
                    p={3}
                    gap={5}
                    alignItems='center'
                    background={sectionItemBg}
                    mb='2'
                    onClick={() =>
                      openPlayer(
                        { ...item, hasItems },
                        sectionTitle,
                        player.type
                      )
                    }
                  >
                    <Image
                      src={item.picture}
                      rounded={6}
                      height='60px'
                      width='60px'
                    />
                    <Heading size='sm' noOfLines={3}>
                      {item.name}
                    </Heading>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Player
