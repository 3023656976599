import axios from 'axios'
import { baseUrl } from 'constants/common'

export const request = async (user, method, params, body) => {
  params = {
    login: user.login,
    password: user.password,
    ...params,
    lang: 'ru',
    v: '1.0',
    app: 'sado_web',
    device_info: window.clientInformation.userAgent
  }

  const response = await axios({
    method,
    params,
    url: baseUrl,
    data: body
      ? {
          username: user.login,
          password: user.password,
          ...body
        }
      : null
  })
  const { data } = response
  if (data.error) {
    throw new Error(data.error_msg)
  }
  return response.data
}

export const nativeRequest = async url => {
  const response = await axios({
    method: 'get',
    url
  })
  const { data } = response
  if (data.error) {
    throw new Error(data.error_msg)
  }
  return response.data
}
