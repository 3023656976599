const userLocalStorageKey = 'sw-user'

const userLocalStorage = {
  get() {
    return JSON.parse(localStorage.getItem(userLocalStorageKey) ?? '{}')
  },
  set(value) {
    localStorage.setItem(userLocalStorageKey, JSON.stringify(value))
  },
  destroy() {
    localStorage.removeItem(userLocalStorageKey)
  }
}

export default userLocalStorage
