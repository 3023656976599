const playerTypes = {
  radio: 'radio',
  podcasts: 'podcasts',
  music: 'music',
  books: 'books',
  book: 'books',
  podcast: 'podcasts',
  home: 'home'
}

export default playerTypes
