import { atom } from 'recoil'

const radioState = atom({
  key: 'state/radio',
  default: {
    genres: [],
    isLoading: false
  }
})

export default radioState
