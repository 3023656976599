import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { favoriteTitle, lastListenedTitle, popularTitle } from 'constants/text'
import radioState from 'state/radio'
import { reduceItems } from 'utils/genres'

import useRequest from './useRequest'

const useRadio = () => {
  const [radio, setRadio] = useRecoilState(radioState)
  const { request } = useRequest()

  const setIsLoading = useCallback(
    isLoading => setRadio(radio => ({ ...radio, isLoading })),
    [setRadio]
  )

  const getFavoriteRadio = useCallback(async () => {
    const data = await request('get', {
      action: 'get_favorite',
      section: 'radio'
    })
    if (!data.error) {
      const genres = data.radio_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getPopularRadio = useCallback(async () => {
    const data = await request('get', {
      action: 'get_trending',
      section: 'radio'
    })

    if (!data.error) {
      const genres = data.radio_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getLastListenedRadio = useCallback(async () => {
    const data = await request('get', {
      action: 'get_lastlisten',
      section: 'radio'
    })
    if (!data.error) {
      const genres = data.radio_genres ?? []

      const items = reduceItems(genres)

      return items
    }
  }, [request])

  const getRadio = useCallback(async () => {
    setIsLoading(true)
    const data = await request('get', {
      action: 'get',
      section: 'radio'
    })
    if (!data.error) {
      const favoriteItems = await getFavoriteRadio()
      const lastListenedItems = await getLastListenedRadio()
      const popularItems = await getPopularRadio()

      const genres =
        [
          { title: favoriteTitle, items: favoriteItems },
          { title: lastListenedTitle, items: lastListenedItems },
          { title: popularTitle, items: popularItems },
          ...data.radio_genres
        ] ?? []

      setRadio(radio => ({
        ...radio,
        genres: genres.filter(genre => genre.items.length),
        isLoading: false
      }))
    } else {
      setIsLoading(false)
    }
  }, [
    request,
    setRadio,
    setIsLoading,
    getFavoriteRadio,
    getLastListenedRadio,
    getPopularRadio
  ])

  const resetRadio = useCallback(() => {
    setRadio(radio => ({ ...radio, genres: [] }))
  }, [setRadio])

  return {
    radio,
    getRadio,
    resetRadio
  }
}

export default useRadio
