import { Flex, Icon, Link, useColorModeValue } from '@chakra-ui/react'
import { Link as RouterLink, useMatch } from 'react-router-dom'

const NavItem = ({ children, icon, path, ...props }) => {
  const isActiveLink = useMatch(path)

  const activeLinkBg = useColorModeValue('gray.700', 'gray.100')
  const activeLinkColor = useColorModeValue('gray.100', 'gray.700')
  const linkHoverBg = useColorModeValue('gray.100', 'gray.800')
  const activeLinkHoverBg = useColorModeValue('gray.800', 'gray.300')

  return (
    <Link
      as={RouterLink}
      to={path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align='center'
        p='4'
        mx='4'
        mb='2'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        bg={isActiveLink ? activeLinkBg : undefined}
        color={isActiveLink ? activeLinkColor : undefined}
        _hover={{
          bg: isActiveLink ? activeLinkHoverBg : linkHoverBg
        }}
        {...props}
      >
        {icon && <Icon mr='4' fontSize='16' as={icon} />}
        {children}
      </Flex>
    </Link>
  )
}

export default NavItem
