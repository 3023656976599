import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  CloseButton,
  Link,
  Image
} from '@chakra-ui/react'

import NavItem from './NavItem'

const SidebarContent = ({ onClose, links, ...props }) => {
  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...props}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between'>
        <Text fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
          Sado
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {links.map(link => (
        <NavItem path={link.path} key={link.path} icon={link.icon}>
          {link.title}
        </NavItem>
      ))}
      <Box
        paddingX={4}
        paddingY={2}
        display={{ base: 'flex', md: 'block' }}
        gap='2'
      >
        <Box mb='2'>
          <Link
            href='https://sadoapp.tj/app/latest.apk'
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            w='100%'
          >
            <Image src='/sado/assets/Android.webp' borderRadius='lg' w='100%' />
          </Link>
        </Box>
        <Box mb='2'>
          <Link
            href='https://apps.apple.com/tj/app/%D1%81%D0%B0%D0%B4%D0%BE/id1597556172'
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            w='100%'
          >
            <Image
              w='100%'
              src='/sado/assets/AppStore.webp'
              borderRadius='lg'
            />
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default SidebarContent
