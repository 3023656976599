import { Autoplay, Grid, Pagination } from 'swiper'

export const singleRowBreakpoints = {
  0: {
    slidesPerView: 1
  },
  500: {
    slidesPerView: 2
  },
  768: {
    slidesPerView: 3
  },
  1200: {
    slidesPerView: 5
  }
}

export const multipleRowBreakpoints = {
  0: {
    slidesPerView: 1
  },
  768: {
    slidesPerView: 2
  },
  1200: {
    slidesPerView: 3
  }
}

export const gridSwiperProps = {
  spaceBetween: 10,
  grid: { rows: 2, fill: 'row' },
  modules: [Grid, Pagination, Autoplay],
  breakpoints: multipleRowBreakpoints,
  pagination: true,
  autoplay: {
    delay: 20000
  }
}

export const swiperProps = {
  spaceBetween: 10,
  modules: [Pagination, Autoplay],
  breakpoints: singleRowBreakpoints,
  pagination: true,
  autoplay: {
    delay: 10000
  }
}
