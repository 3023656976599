import { useState } from 'react'
import {
  useColorModeValue,
  Text,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Stack,
  InputGroup,
  InputLeftAddon
} from '@chakra-ui/react'
import useLogin from 'hooks/useLogin'

import LoginLayout from 'layouts/LoginLayout'

const LoginPage = () => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const { user, getPassword, checkPassword } = useLogin()

  const loginHandler = event => {
    event.preventDefault()
    if (!!user.login.length) {
      checkPassword(password)
    } else {
      getPassword(login)
    }
  }

  const handleChange = event => {
    event.persist()
    const { name, value } = event.target
    if (name === 'login') setLogin(value)
    if (name === 'password') setPassword(value)
  }

  return (
    <LoginLayout>
      <form onSubmit={loginHandler}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Регистрация</Heading>
            <Text fontSize={'lg'} align='center' color={'gray.600'}>
              Введите номер телефона, и мы отправим сообщение с паролем
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id='login'>
                <FormLabel>Номер телефона</FormLabel>
                <InputGroup>
                  <InputLeftAddon children='+992' />
                  <Input
                    type='tel'
                    inputMode='numeric'
                    autoFocus
                    name='login'
                    isRequired
                    value={login}
                    onChange={handleChange}
                    readOnly={!!user.login.length}
                    placeholder='Введите номер телефона'
                  />
                </InputGroup>
              </FormControl>
              {!!user.login.length && (
                <FormControl id='password'>
                  <FormLabel>Пароль</FormLabel>
                  <Input
                    type='password'
                    name='password'
                    autoFocus={!!user.login.length}
                    value={password}
                    isRequired
                    onChange={handleChange}
                    placeholder='Введите пароль'
                  />
                </FormControl>
              )}
              <Stack spacing={10}>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500'
                  }}
                  isLoading={user.isLoading}
                  type='submit'
                  isDisabled={
                    !user.login.length
                      ? login.length !== 9
                      : login.length !== 9 || !password.length
                  }
                >
                  {!user.login.length ? 'Получить код' : 'Зарегистрироваться'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </LoginLayout>
  )
}

export default LoginPage
