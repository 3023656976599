import { useEffect } from 'react'
import {
  Heading,
  Box,
  useColorModeValue,
  Flex,
  Image,
  Button,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer
} from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import usePlayer from 'hooks/usePlayer'
import MainLayout from 'layouts/MainLayout'
import Loading from 'components/Loading'
import { gridSwiperProps, swiperProps } from 'constants/carousel'
import { isItemColumns, reduceChildItems } from 'utils/genres'
import playerTypes from 'constants/playerTypes'
import useMusic from 'hooks/useMusic'

const MusicPage = () => {
  const { music, getMusic, resetMusic, getMoreMusic } = useMusic()
  const { openPlayer, player } = usePlayer()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const boxBorderColor = useColorModeValue('gray.300', 'gray.600')
  const boxBg = useColorModeValue('white', 'gray.800')
  const sectionItemBg = useColorModeValue('white', 'gray.700')

  useEffect(() => {
    getMusic()

    return () => {
      resetMusic()
    }

    // eslint-disable-next-line
  }, [])

  const openMoreContent = (title, url) => {
    getMoreMusic(title, url)
    onOpen()
  }

  if (music.isLoading && !player.isVisible) {
    return <Loading />
  }

  return (
    <>
      <MainLayout>
        {music.genres.map(({ title, items, more_text, more_url }) => (
          <Box key={title} py='2'>
            <Flex
              justifyContent='space-between'
              width='100%'
              py='2'
              align='center'
            >
              <Heading size='md' mb={3}>
                {title}
              </Heading>
              {!!(more_text && more_url) && (
                <Button
                  size='sm'
                  onClick={() => openMoreContent(title, more_url)}
                >
                  {more_text}
                </Button>
              )}
            </Flex>
            {isItemColumns(title) ? (
              <Swiper {...gridSwiperProps}>
                {reduceChildItems(items).map(item => (
                  <SwiperSlide
                    onClick={() => openPlayer(item, title, playerTypes.music)}
                    key={item.id}
                  >
                    <Flex
                      flex={1}
                      borderColor={boxBorderColor}
                      borderWidth={1}
                      rounded={10}
                      p={3}
                      gap={5}
                      alignItems='center'
                      background={boxBg}
                    >
                      <Image src={item.picture} rounded={10} height='60px' />

                      <Heading size='sm' noOfLines={1}>
                        {item.name}
                      </Heading>
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Swiper {...swiperProps}>
                {items.map(item => (
                  <SwiperSlide
                    onClick={() => openPlayer(item, title, playerTypes.music)}
                    key={item.id}
                  >
                    <Flex
                      borderColor={boxBorderColor}
                      borderWidth={1}
                      rounded={10}
                      flex={1}
                      p={5}
                      justifyContent='center'
                      flexWrap='wrap'
                      bg={boxBg}
                      pointerEvents='none'
                    >
                      <Box flexBasis='100%'>
                        <Image src={item.picture} rounded={10} width='100%' />
                      </Box>
                      <Heading
                        noOfLines={1}
                        textAlign='center'
                        mt='2'
                        size='sm'
                      >
                        {item.name}
                      </Heading>
                    </Flex>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>
        ))}
      </MainLayout>
      <Drawer onClose={onClose} isOpen={isOpen} size={'md'}>
        <DrawerOverlay />
        <DrawerContent bg={boxBg}>
          <DrawerCloseButton />
          <DrawerHeader>{music.more.title}</DrawerHeader>
          <DrawerBody>
            <Flex
              flexDir='column'
              width='100%'
              paddingX={{ base: 0, lg: '6' }}
              gap='2'
            >
              {music.more.items.map(item => (
                <Flex
                  key={item.hasItems ? item.chapter_id : item.id}
                  borderColor={boxBorderColor}
                  borderWidth={1}
                  rounded={10}
                  p={3}
                  gap={5}
                  alignItems='center'
                  background={sectionItemBg}
                  mb={2}
                  onClick={() => {
                    openPlayer(item, music.more.title, playerTypes.music)
                    onClose()
                  }}
                >
                  <Image src={item.picture} rounded={6} height='60px' />
                  <Heading size='sm' noOfLines={3}>
                    {item.name}
                  </Heading>
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MusicPage
