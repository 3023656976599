import { atom } from 'recoil'

const searchState = atom({
  key: 'state/search',
  default: {
    text: '',
    result: [],
    isLoading: false
  }
})

export default searchState
